import React, { useEffect, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Form,
  Modal,
  Select,
  Upload,
  notification,
} from "antd";
import { ScholarshipService_ } from "services/scholarship.service";
import { useForm } from "antd/lib/form/Form";
import { Input, InputNumber, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { API_UPLOAD_URL } from "configs/AppConfig";
import { beforeUpload } from "utils/utilities";
import { PlusOutlined } from "@ant-design/icons";
import { useForceUpdate } from "hooks/useForceUpdate";
import { cloneDeep } from "lodash";
import moment from "moment";

const ScholarshipInput = ({ scholarshipInfo, buttonStyle, termsStyle }) => {
  console.log("🚀 ~ ScholarshipInput ~ scholarshipInfo:", scholarshipInfo);
  const mobileWidth = window.innerWidth < 750;

  const [isLoading, setIsLoading] = useState(true);
  const [ageField, setAgeField] = useState(null);
  const [form] = useForm();
  const forceUpdate = useForceUpdate();
  const [termModalVisible, setTermModalVisible] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (scholarshipInfo?.fields) {
      scholarshipInfo?.fields?.forEach((scholarshipField) => {
        if (scholarshipField?.field?.key === "age")
          setAgeField(scholarshipField);
      });
    }
  }, [scholarshipInfo]);

  function containsHttpInFirst20Words(inputString) {
    const words = inputString.split(/\s+/).slice(0, 20);

    const first20Words = words.join(" ");

    const urlPattern = /https?:\/\/[^\s]+/;

    return urlPattern.test(first20Words);
  }

  const handleTermsClick = () => {
    const termsLink = "https://lenny.ai/terms";
    if (containsHttpInFirst20Words(scholarshipInfo?.terms)) {
      window.open(scholarshipInfo?.terms, "_blank");
    } else {
      setTermModalVisible(true);
    }
  };

  // Handle click for Privacy policy
  const handlePrivacyClick = () => {
    const privacyLink = "https://lenny.ai/privacy";
    if (containsHttpInFirst20Words(scholarshipInfo?.privacy)) {
      window.open(privacyLink, "_blank");
    } else {
      setPrivacyModalVisible(true);
    }
  };

  const submitApplication = () => {
    setIsLoading(true);
    form.validateFields().then((data) => {
      console.log(data);
      const { firstName, lastName, ...restData } = data;

      // Concatenate first and last names into a single "name" field
      const fullName = `${firstName} ${lastName}`;

      // Add the concatenated name to the form data
      restData.name = fullName;
      restData.firstName = firstName;
      restData.lastName = lastName;
      // Add scholarshipId to the form data
      restData.scholarshipId = scholarshipInfo.id;

      ScholarshipService_.submitApplication(scholarshipInfo.id, restData)
        .then((data) => {
          if (data?.data?.id) {
            // notification.success({
            //   description: "Applicant has been successfully submitted",
            //   message: "Submitted",
            // });
            const modal = Modal.success({
              title: "Application Submitted",
              content: "Applicant has been successfully submitted.",
            });

            // Close the modal automatically after 3 seconds
            setTimeout(() => modal.destroy(), 3000);
            form.resetFields();
          }
        })
        .catch((error) => {
          // Extract the error message from the response
          console.log("error", error);
          const errorMessage =
            error?.description ||
            "An unexpected error occurred.Please try again";

          // Show an error modal
          Modal.error({
            title: "Application Failed",
            content: errorMessage,
          });
        })
        .finally(() => setIsLoading(false));
    });
  };

  function validateAge(value) {
    const eligibilityType = ageField?.attributes?.eligibilityType;
    const eligibilityValue = ageField?.attributes?.eligibilityValue;

    if (!eligibilityType || !eligibilityValue || !value) {
      return true;
    }

    const currentDate = moment();
    const age = currentDate.diff(value, "years");

    // Check if the user is born in the current year but hasn't had their birthday yet
    const hasBirthdayPassed =
      currentDate.month() > value.month() ||
      (currentDate.month() === value.month() &&
        currentDate.date() >= value.date());

    if (!hasBirthdayPassed) {
      age--;
    }

    switch (eligibilityType) {
      case "is":
        return {
          isValid: age?.toString() === eligibilityValue,
          errorMessage: `Age should be exactly ${eligibilityValue}`,
        };
      case "lessthanOrIs":
        return {
          isValid: age <= eligibilityValue,
          errorMessage: `Age should be less than or equal to ${eligibilityValue}`,
        };
      case "greaterthanOrIs":
        return {
          isValid: age >= eligibilityValue,
          errorMessage: `Age should be greater than or equal to ${eligibilityValue}`,
        };
      default:
        return { isValid: true, errorMessage: null };
    }
  }

  function validateDate(value, scholarshipField) {
    const eligibilityType = scholarshipField?.attributes?.eligibilityType;
    const eligibilityValue = scholarshipField?.attributes?.eligibilityValue;

    if (!eligibilityType || !eligibilityValue || !value) {
      return true;
    }

    const fieldName = scholarshipField?.field?.name;
    switch (eligibilityType) {
      case "between":
        return {
          isValid: moment(value).isBetween(
            moment(eligibilityValue[0], "DD/MM/YYYY"),
            moment(eligibilityValue[1], "DD/MM/YYYY")
          ),
          errorMessage: `${fieldName} should be between ${moment(
            eligibilityValue[0],
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")} and ${moment(
            eligibilityValue[1],
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}`,
        };
      case "before":
        return {
          isValid: moment(value).isBefore(
            moment(eligibilityValue, "DD/MM/YYYY")
          ),
          errorMessage: `${fieldName} should be before ${moment(
            eligibilityValue,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}`,
        };
      case "after":
        return {
          isValid: moment(value).isAfter(
            moment(eligibilityValue, "DD/MM/YYYY")
          ),
          errorMessage: `${fieldName} should be after ${moment(
            eligibilityValue,
            "DD/MM/YYYY"
          ).format("DD/MM/YYYY")}`,
        };
      default:
        return { isValid: true, errorMessage: null };
    }
  }

  function validateOption(value, scholarshipField) {
    const eligibilityType = scholarshipField?.attributes?.eligibilityType;
    const eligibilityValue = scholarshipField?.attributes?.eligibilityValue;

    if (!eligibilityValue || !value) {
      return true; // no eligibilityValue provided, always valid
    }
    let validValues = []; // eligibility Value  in terms of keys
    let matchValues = []; // eligibility Value  in terms of options value
    if (Array.isArray(eligibilityValue)) {
      validValues = eligibilityValue;
    } else {
      validValues = eligibilityValue.split(",");
    }
    matchValues = validValues.map(
      (key) => scholarshipField?.field.options[key]
    );

    const strValues = matchValues.join(", ");
    const fieldName = scholarshipField?.field?.name;

    const isValid = validValues.includes(value); // check if the selected value is in the eligible values array
    switch (eligibilityType) {
      case "is":
        return {
          isValid,
          errorMessage: `${fieldName} should equal to ${strValues}`,
        };
      case "oneOf":
        return {
          isValid,
          errorMessage: `${fieldName} should one of  ${strValues}`,
        };
      case "not":
        return {
          isValid: !isValid,
          errorMessage: `${fieldName} should one of  ${strValues}`,
        };
      case "notOneOf":
        return {
          isValid: !isValid,
          errorMessage: `${fieldName} should be different from ${strValues} `,
        };
      default:
        return { isValid: true, errorMessage: null }; // unsupported eligibilityType
    }
  }

  const getDynamicFields = () => {
    const allFieldExceptAge = scholarshipInfo?.fields?.filter(
      (field) => field.field.key !== "age"
    );
    return [
      ...(allFieldExceptAge ?? []),
      ...(scholarshipInfo?.requirements ?? []),
    ].map((scholarshipField, i) => prepareFieldOptions(scholarshipField, i));
  };

  // const handleChange = (info, namePath) => {
  //   const fieldValue = form.getFieldValue(namePath[0]);
  //   if (info?.file?.status === "removed") {
  //     fieldValue[namePath[1]][namePath[2]] = null;
  //     form.setFieldsValue({
  //       [namePath[0]]: fieldValue,
  //     });
  //   } else if (info?.file?.status === "done") {
  //     const data = cloneDeep(info?.file?.response?.data ?? {});
  //     data.publicUrl = null;
  //     fieldValue[namePath[1]][namePath[2]] = JSON.stringify(data);
  //     form.setFieldsValue({
  //       [namePath[0]]: fieldValue,
  //     });
  //   }
  //   forceUpdate();
  // };
  const handleChange = (info, namePath) => {
    const fieldValue = form.getFieldValue(namePath[0]);

    if (info?.file?.status === "removed") {
      fieldValue[namePath[1]][namePath[2]] = null;
    } else if (info?.file?.status === "done") {
      const responseData = info?.file?.response?.data;
      if (responseData) {
        const { publicUrl } = responseData;
        fieldValue[namePath[1]][namePath[2]] = publicUrl;
      }
    } else if (info?.file?.status === "error") {
      console.error("File upload failed:", info.file.error);
    }

    // Update form field value
    form.setFieldsValue({
      [namePath[0]]: fieldValue,
    });

    forceUpdate();
  };

  const getUploadIcon = (namePath) => {
    return form.getFieldValue(namePath) ? null : (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
  };
  const today = moment().endOf("day");
  const prepareFieldOptions = (scholarshipField, i) => {
    const field_ = scholarshipField?.field;
    const isRequirement = field_?.type === "requirement";
    let fieldName = isRequirement ? scholarshipField?.title : field_?.name;
    let fieldDescription = isRequirement ? scholarshipField?.description : null;
    fieldName = fieldName ?? field_?.name;
    switch (field_?.dataType) {
      case "option":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
                {
                  validator: (rule, value, callback) => {
                    const validateResult = validateOption(
                      value,
                      scholarshipField
                    );
                    callback(
                      !validateResult?.isValid
                        ? validateResult?.errorMessage
                        : undefined
                    );
                  },
                },
              ]}
            >
              <Select
                options={Object.keys(field_.options ?? {}).map((key) => ({
                  label: field_.options[key],
                  value: key,
                }))}
              />
            </Form.Item>
          </span>
        );

      case "date":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
                {
                  validator: (rule, value, callback) => {
                    const validateResult = validateDate(
                      value,
                      scholarshipField
                    );
                    callback(
                      !validateResult?.isValid
                        ? validateResult?.errorMessage
                        : undefined
                    );
                  },
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => current && current > today}
              />
            </Form.Item>
          </span>
        );
      case "editor":
      case "textarea":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
              ]}
            >
              <TextArea />
            </Form.Item>
          </span>
        );
      case "file": {
        const namePath = ["applicantFields", i, "fieldValue"];
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={namePath}
              label={fieldName}
              rules={[
                {
                  required: scholarshipField?.attributes?.optional === false,
                  message: `Please select ${fieldName}`,
                },
              ]}
            >
              <Upload
                name="file"
                listType="picture-card"
                action={API_UPLOAD_URL}
                onChange={(info) => handleChange(info, namePath)}
                onPreview={(file) => {
                  window.open(
                    file?.response?.data?.publicUrl,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                {getUploadIcon(namePath)}
              </Upload>
            </Form.Item>
          </span>
        );
      }
      case "checkbox":
        return (
          <span key={scholarshipField.id}>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ paddingLeft: "10px", paddingRight: "10px" }}
              name={["applicantFields", i, "fieldValue"]}
              label={fieldName}
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
              ]}
            >
              <Checkbox.Group
                options={Object.keys(field_.options ?? {}).map((key) => ({
                  label: field_.options[key],
                  value: key,
                }))}
              />
            </Form.Item>
          </span>
        );

      case "link":
      case "email":
      case "phone":
      case "number":
      default:
        return (
          <div key={scholarshipField.id} style={{ width: "100%" }}>
            {/* Hidden Field */}
            <Form.Item
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              name={["applicantFields", i, "fieldId"]}
              hidden
              initialValue={field_.id || ""}
            >
              <Input />
            </Form.Item>

            {/* Field Item with Title as Label */}
            <Form.Item
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                width: "100%",
              }}
              name={["applicantFields", i, "fieldValue"]}
              //initialValue={field_.id || ""}
              label={
                <div
                  style={{
                    display: "block", // Ensures it takes the full width
                    color: "black",
                    width: "100%", // Set to full width
                  }}
                >
                  <p
                    style={{
                      marginBottom: "5px",
                      whiteSpace: "normal", // Allows text wrapping
                      width: "100%", // Ensure full width
                      color: "black",
                    }}
                  >
                    {fieldName} {/* Title */}
                  </p>
                  {fieldDescription && (
                    <div
                      style={{ color: "gray", marginTop: "5px", width: "100%" }}
                    >
                      {fieldDescription} {/* Description */}
                    </div>
                  )}
                </div>
              }
              rules={[
                { required: scholarshipField?.attributes?.optional === false },
                {
                  type: ["email", "number"].includes(field_?.dataType)
                    ? field_?.dataType
                    : "string",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </div>
        );
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={submitApplication}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: mobileWidth ? "100%" : "50% auto",
          }}
        >
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            name="email"
            label="Email"
            rules={[{ type: "email", required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            name="phone"
            label="Phone"
            rules={[{ min: 10, max: 13, required: false }]}
          >
            <Input placeholder="91-9999999999" />
          </Form.Item>
          <Form.Item
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
            name="dob"
            label="Date of Birth"
            rules={[
              { required: true, message: "Date of Birth is required" },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject("Please select a valid date.");
                  }
                  // Check if the date is in the future
                  if (moment(value).isAfter(moment(), "day")) {
                    return Promise.reject(
                      "Date of Birth cannot be in the future."
                    );
                  }
                  // Custom age validation logic
                  const validateResult = validateAge(value);
                  if (!validateResult?.isValid) {
                    return Promise.reject(validateResult?.errorMessage);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          {getDynamicFields()}
        </div>
        <span
          style={{
            ...termsStyle,
          }}
        >
          <Checkbox
            onChange={() => {
              setIsChecked(!isChecked);
            }}
          />
          {/* <span
            style={{
              paddingLeft: "5px",
            }}
          >

            I agree to the <a href="https://lenny.ai/terms" target="_blank">
              Terms of use{" "}
            </a>{" "}, <a href="https://lenny.ai/privacy" target="_blank">
              {" "}
              Privacy policy{" "}
            </a>, and the Scholarship Terms.
          </span> */}
          <span style={{ paddingLeft: "5px" }}>
            I agree to the{" "}
            <a href="https://lenny.ai/terms" target="_blank">
              Terms of use{" "}
            </a>{" "}
            ,
            <a href="https://lenny.ai/privacy" target="_blank">
              Privacy policy{" "}
            </a>{" "}
            and{" "}
            <a onClick={handleTermsClick} style={{ cursor: "pointer" }}>
              the Scholarship Terms.
            </a>
          </span>
        </span>
        <Form.Item
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "20px",
            ...buttonStyle,
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "auto",
              backgroundColor: "rgba(16, 49, 184, 1)",
            }}
            disabled={!isChecked}
          >
            Submit Application
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title="Terms and Conditions"
        visible={termModalVisible}
        onOk={() => setTermModalVisible(false)}
        onCancel={() => setTermModalVisible(false)}
      >
        <p>{scholarshipInfo?.terms}</p>
      </Modal>

      <Modal
        title="Privacy Policy"
        visible={privacyModalVisible}
        onOk={() => setPrivacyModalVisible(false)}
        onCancel={() => setPrivacyModalVisible(false)}
      >
        <p>{scholarshipInfo?.privacy}</p>
      </Modal>
    </>
  );
};

export default ScholarshipInput;
