import React, { useEffect, useState } from "react";
// import LoginForm from '../../components/LoginForm'
import { ClockCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import moment from "moment";

export const Title = (props) => {
  return (
    <>
      <h3
        style={{
          ...props?.style,
        }}
      >
        {props?.scholarshipInfo?.title || "No NAme"}
      </h3>
      <p
        style={{
          ...props?.style,
        }}
      >
        <ClockCircleOutlined />{" "}
        {moment(
          props?.scholarshipInfo?.recurrenceConfig?.start ?? new Date()
        ).format("MMMM DD, YYYY")}{" "}
        -{" "}
        {moment(
          props?.scholarshipInfo?.recurrenceConfig?.deadline ?? new Date()
        ).format("MMMM DD, YYYY")}
      </p>
    </>
  );
};

export const DontWait = (props) => {
  return (
    <>
      <h1 className="" style={{ marginBottom: "-7px", ...props?.style }}>
        {props?.text}
      </h1>
      <p
        className=""
        style={{
          ...props?.style,
        }}
      >
        {props?.subText}
      </p>
    </>
  );
};

// export const Header = (props) => {
//   return (
//     <>
//       <div
//         style={{
//           borderBottomStyle: "double",
//           borderWidth: "1px",
//           borderColor: "lightgray",
//           cursor: "pointer",
//         }}
//       >
//         <a
//           href="https://lenny.ai/"
//           style={{
//             textDecoration: "none",
//             color: "black",
//             cursor: "pointer",
//           }}
//         >
//           <img
//             src="/new/icon.png"
//             style={{ width: "200px", padding: "10px 5px 5px 20px" }}
//           />
//         </a>
//       </div>
//     </>
//   );
// };

export const Header = ({ logo }) => {
  const defaultLogo = "/new/icon.png";

  // Function to render the logo based on its type
  const renderLogo = () => {
    if (!logo) {
      // If logo is null or undefined, show the default logo
      return (
        <img
          src={defaultLogo}
          alt="Default Logo"
          style={{
            width: "200px",
            padding: "10px 5px 5px 20px",
          }}
        />
      );
    }

    if (typeof logo === "string") {
      // If logo is a URL string, render it as an image
      return (
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "150px",
            padding: "10px 5px 5px 20px",
          }}
        />
      );
    }

    if (React.isValidElement(logo)) {
      // If logo is a valid React element (e.g., SVG), clone and render it
      return React.cloneElement(logo, {
        style: {
          width: "200px",
          padding: "10px 5px 5px 20px",
          ...logo?.props?.style,
        },
      });
    }

    // Fallback in case logo is an unexpected type
    return (
      <img
        src={defaultLogo}
        alt="Default Logo"
        style={{
          width: "200px",
          padding: "10px 5px 5px 20px",
        }}
      />
    );
  };

  return (
    <div
      style={{
        borderBottomStyle: "double",
        borderWidth: "1px",
        borderColor: "lightgray",
        cursor: "pointer",
        padding: "8px",
      }}
    >
      <a
        href="https://lenny.ai/"
        style={{
          textDecoration: "none",
          color: "black",
          cursor: "pointer",
        }}
      >
        {renderLogo()}
      </a>
    </div>
  );
};

export const Passage = (props) => {
  return (
    <>
      <div>
        <p
          style={{
            ...props?.style,
          }}
        >
          {props?.text}
        </p>
      </div>
    </>
  );
};

export const Award = (props) => {
  return (
    <>
      <div>
        <h3
          style={{
            color: "#FF33CC",
            ...props?.style,
            marginTop: "20px",
          }}
        >
          Scholarship Awards
        </h3>
        <p
          style={{
            color: "#FFFFFF",
            ...props?.style,
          }}
        >
          {" "}
          ${props?.scholarshipInfo?.awards}:
        </p>
      </div>
    </>
  );
};

export const Description = (props) => {
  return (
    <>
      <div>
        <h3
          style={{
            ...props?.style,
            marginTop: "20px",
          }}
        >
          Scholarship description
        </h3>
        <p
          className={props?.className}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props?.scholarshipInfo?.description),
          }}
        />
      </div>
    </>
  );
};
