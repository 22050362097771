import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Text from "antd/lib/typography/Text";
import ScholarshipFields from "./scolarship-fields";
import ScholarshipCountDown from "./scholarship-countdown";
import {
  Title,
  DontWait,
  Header,
  Passage,
  Award,
  Description,
} from "./component/Title.js";
import { Footer } from "./component/Footer.js";
import moment from "moment";
import "./component/style.css";
const Theme2 = ({ scholarshipInfo, setScolarshipInfo }) => {
  console.log("🚀 ~ Theme2 ~ scholarshipInfo:", scholarshipInfo);
  const mobileWidth = window.innerWidth < 750;

  const renderRightScreen = () => {
    if (moment(scholarshipInfo?.recurrenceConfig?.start).isAfter()) {
      return (
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={20}>
            <h1 className="text-white">
              This scholarship hasn’t been launched yet. We’ll remind you when
              it's open for applications.
            </h1>
            <p className="text-white">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </Col>
        </Row>
      );
    } else if (moment(scholarshipInfo?.recurrenceConfig?.deadline).isBefore()) {
      return (
        <Row style={{ minHeight: "100vh" }}>
          <Col xs={0} sm={0} md={0} lg={20}>
            <h1 className="text-white">This scholarship has been closed.</h1>
            <p className="text-white">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </Col>
        </Row>
      );
    } else {
      return (
        <>
          <DontWait text={"Apply now before it's too late"} subText={""} />
          <ScholarshipCountDown
            scholarshipInfo={scholarshipInfo}
            timeFinished={() => setScolarshipInfo(scholarshipInfo)}
            style={{
              color: "#000000",
            }}
            mainStyle={{
              background: "#FFFFFF",
            }}
          />
          <div
            style={{
              marginTop: "15px",
            }}
          >
            <ScholarshipFields
              scholarshipInfo={scholarshipInfo}
              buttonStyle={{
                textAlign: "left",
                marginTop: "40px",
              }}
              termsStyle={{
                color: "#FFFFFF",
                float: "left",
                marginLeft: "10px",
              }}
            />
          </div>
          <Passage
            style={{
              color: "#FFFFFF",
            }}
            text={`The worthy is operated by ${
              scholarshipInfo?.user?.firstName
            } and runs from ${moment(
              scholarshipInfo?.recurrenceConfig?.start ?? new Date()
            ).format("MMMM DD, YYYY")} to ${moment(
              scholarshipInfo?.recurrenceConfig?.deadline ?? new Date()
            ).format(
              "MMMM DD, YYYY"
            )} . All students who are at least 16 years old and are legal resident of United States or India are eligible (additional may apply). Void where prohibited by law. No purchase necessary to enter or win.`}
          />
        </>
      );
    }
  };

  return (
    <>
      <div className={`h-100`} style={{}}>
        <Header />
      </div>
      <div
        style={{
          padding: "30px",
          backgroundImage: "url(/new/theme2a.png)",
          backgroundSize: "cover",
        }}
      >
        <Row style={{ padding: "30px" }}>
          <Col
            style={{
              width: mobileWidth ? "100%" : "40%",
            }}
          >
            <div
              style={{
                background: "rgba(255,255,255,0.7)",
                marginRight: "30px",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Title
                scholarshipInfo={scholarshipInfo}
                style={{
                  color: "#FFFFFF",
                }}
              />
              <div
                style={{
                  marginTop: "25px",
                }}
              >
                <Award
                  scholarshipInfo={scholarshipInfo}
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "25px",
                }}
              >
                <Description
                  scholarshipInfo={scholarshipInfo}
                  className={"whiteColor"}
                  style={{
                    color: "#FFFFFF",
                  }}
                />
              </div>
            </div>
          </Col>
          <Col
            style={{
              width: mobileWidth ? "100%" : "60%",
            }}
          >
            {renderRightScreen()}
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Theme2;
