import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Text from "antd/lib/typography/Text";
import ScholarshipFields3 from "./scolarship-fields3";
import ScholarshipCountDown from "./scholarship-countdown";
import {
  Title,
  DontWait,
  Header,
  Passage,
  Award,
  Description,
} from "./component/Title.js";
import moment from "moment";
import { Footer } from "./component/Footer.js";
import "./component/style.css";
const Theme3 = ({ scholarshipInfo, setScolarshipInfo }) => {
  const mobileWidth = window.innerWidth < 1100;

  const renderRightScreen = () => {
    if (moment(scholarshipInfo?.recurrenceConfig?.start).isAfter()) {
      return (
        <Col
          style={{
            width: mobileWidth ? "100%" : "60%",
            textAlign: "center",
          }}
        >
          <div style={{ background: "#FFFFFF", padding: "20px" }}>
            <h1 className="">
              This scholarship hasn’t been launched yet. We’ll remind you when
              it's open for applications.
            </h1>
            <p className="">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </div>
        </Col>
      );
    } else if (moment(scholarshipInfo?.recurrenceConfig?.deadline).isBefore()) {
      return (
        <Col
          style={{
            width: mobileWidth ? "100%" : "60%",
            textAlign: "center",
          }}
        >
          <div style={{ background: "#FFFFFF", padding: "20px" }}>
            <h1 className="">This scholarship has been closed.</h1>
            <p className="">
              The 2023 scholarship has been closed. Leave us your email and
              we'll update you when/if the scholarship has been opened up.
            </p>
          </div>
        </Col>
      );
    } else {
      return (
        <>
          <Col
            style={{
              width: mobileWidth ? "100%" : "60%",
              textAlign: "center",
            }}
          >
            <div style={{ background: "#FFFFFF", padding: "20px" }}>
              <div
                style={{
                  position: "relative",
                  bottom: "50px",
                }}
              >
                <ScholarshipCountDown
                  scholarshipInfo={scholarshipInfo}
                  timeFinished={() => setScolarshipInfo(scholarshipInfo)}
                  style={{
                    color: "#000000",
                  }}
                  mainStyle={{
                    background: "#f3f3f4",
                  }}
                />
              </div>
              <DontWait
                text={"Apply now before it's too late"}
                subText={""}
                style={{
                  color: "#000000",
                }}
              />
              <div
                style={{
                  marginTop: "15px",
                }}
              >
                <ScholarshipFields3
                  scholarshipInfo={scholarshipInfo}
                  buttonStyle={{
                    textAlign: "left",
                    marginTop: "40px",
                  }}
                  termsStyle={{
                    color: "#000000",
                    float: "left",
                    marginLeft: "10px",
                  }}
                />
              </div>
              <Passage
                style={{
                  color: "#000000",
                }}
                text={`The worthy is operated by ${
                  scholarshipInfo?.user?.firstName
                } and runs from ${moment(
                  scholarshipInfo?.recurrenceConfig?.start ?? new Date()
                ).format("MMMM DD, YYYY")} to ${moment(
                  scholarshipInfo?.recurrenceConfig?.deadline ?? new Date()
                ).format(
                  "MMMM DD, YYYY"
                )} . All students who are at least 16 years old and are legal resident of United States or India are eligible (additional may apply). Void where prohibited by law. No purchase necessary to enter or win.`}
              />
            </div>
          </Col>
        </>
      );
    }
  };

  return (
    <>
      {/* <div className={`h-100`} style={{}}>
      <Header logo="/new/icon.png" />
       
      </div> */}
      <div style={{ background: "#f0f2f5", padding: "10px 20px" }}>
        <Row justify="space-between" align="middle">
          {/* Left Logo */}
          <Col>
            <img
              src="/new/icon.png"
              alt="Left Logo"
              style={{ height: "30px" }}
            />
          </Col>

          {/* Right Logo - Render Only If logoUrl Exists */}
          {scholarshipInfo?.logoUrl && (
            <Col>
              <img
                src={scholarshipInfo.logoUrl}
                alt="Right Logo"
                style={{ height: "30px" }}
              />
            </Col>
          )}
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          background:
            "linear-gradient(180deg, #051F4E 0%, #0F30B8 30.45%, #062052 60%)",
          //height: mobileWidth ? "100%" : "70vh",
          overflow: "visible",
        }}
      >
        <div
          style={{
            flex: "1 1 60%",
            display: "flex",
            flexDirection: "column",
            //alignItems: 'center',
            justifyContent: "center",
            paddingLeft: "40px",
            paddingRight:"40px",
            overflow: "visible",
            minHeight: "auto", // Allow dynamic height
            overflowWrap: "break-word", // Prevent overflow
          }}
        >
          <Title
            scholarshipInfo={scholarshipInfo}
            style={{
              color: "#FFFFFF",
              fontFamily: "Poppins", // Font family                  // Font weight                // Font size              // Line height
            }}
          />
          <Award
            scholarshipInfo={scholarshipInfo}
            style={{
              fontFamily: "Poppins",
            }}
          />
          <Description
            scholarshipInfo={scholarshipInfo}
            className={"whiteColor"}
            style={{
              color: "white",
              fontFamily: "Poppins",
            }}
          />
        </div>
        <div
          style={{
            flex: "1 1 40%",
            display: mobileWidth ? "none" : "block",
          }}
        >
          <img
            src="/new/applyChimp.png"
            style={{
              width: "100%",
              height: "100%",
              // objectFit: "cover"
            }}
            alt=""
          />
        </div>
      </div>
      <div style={{ background: "#edeeff", padding: "1px" }}>
        <div
          style={{
            position: "relative",
            top: "-28px",
            textAlign: "center",
          }}
        >
          <ScholarshipCountDown
            scholarshipInfo={scholarshipInfo}
            timeFinished={() => setScolarshipInfo(scholarshipInfo)}
            style={{
              color: "#FF33CC",
            }}
            mainStyle={{
              background: "#f3f3f4",
            }}
          />
        </div>
        <div style={{ textAlign: "center", paddingBottom: "8px" }}>
          <DontWait
            text={"Apply now before it's too late"}
            subText={""}
            style={{
              color: "rgba(16, 49, 184, 1)",
            }}
          />
        </div>
        <div
          style={{
            // marginTop: '15px',
            width: "80%",
            margin: "auto",
          }}
        >
          <ScholarshipFields3
            scholarshipInfo={scholarshipInfo}
            buttonStyle={{
              textAlign: "left",
              marginTop: "40px",
            }}
            termsStyle={{
              color: "#000000",
              float: "left",
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
      <div style={{}}>
        <Footer />
      </div>
    </>
  );
};

export default Theme3;
