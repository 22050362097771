export const Footer = (props) => {
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #161C5C 0%, #8001AB 100%)",
        fontFamily: "Poppins",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTopStyle: "double",
          borderWidth: "1px",
          // borderColor: "lightgray",
          paddingTop: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <p
            style={{
              marginLeft: "30px",
              borderWidth: "1px",
              borderRightStyle: "double",
              paddingRight: "10px",
              color: "white",
            }}
          >
            <a
              href="https://lenny.ai/terms"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              Terms of Use
            </a>
          </p>
          <p
            style={{
              marginLeft: "10px",
              borderWidth: "1px",
              borderRightStyle: "double",
              paddingRight: "10px",
            }}
          >
            <a
              href="https://lenny.ai/privacy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white" }}
            >
              Privacy Policy
            </a>
          </p>
          <p
            style={{
              marginLeft: "10px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                "https://lenny.ai/do-not-sell-my-personal-information",
                "_blank"
              );
            }}
          >
            Do not Sell my Personal information
          </p>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <p style={{ color: "white" }}>Find More Scholarship on</p>
          <a href="https://lenny.ai/" target="_blank" rel="noopener noreferrer">
            <img
              src="/new/icon.png"
              style={{ height: "20px", margin: "2px 30px 0px 5px" }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          marginTop: "8px",
          marginLeft: "30px", // Same margin as the top div elements
          fontSize: "12px",
          color: "gray",
        }}
      >
        <p>
          No purchase necessary to enter or apply for any scholarship or
          sweepstakes.
        </p>
      </div>
    </div>
  );
};
